<template>
  <v-card color="grey lighten-3" tile>
    <v-card-text class="pa-2">
      <v-row justify="center">
        <v-col cols="12" v-for="(d, i) in divMap" :key="d.id" :class="{'pt-0': i > 0}">
          <v-card class="pa-0">
            <v-card-text class="title px-2 py-1">
              {{d.name}}
              <div class="mt-n1 align-center grey--text caption">
                {{d.live.length}} Live Matches ({{d.remaining.length}} total remaining)
                <v-btn
                  color="color3"
                  text x-small
                  @click.stop="toggle(d.id)"
                  v-if="d.remaining.length > 0 && d.remaining.length != d.live.length"
                >show {{remaining.includes(d.id) ? 'live only' : 'all remaining'}}</v-btn></div>
            </v-card-text>
            <v-expand-transition>
              <v-card-text v-if="d.matches.length > 0" class="pa-0">
                <v-container fluid class="pa-2">
                  <v-row dense>
                    <v-col cols="6" md="3" lg="2" v-for="match in d.matches" :key="match.id">
                      <match-card
                        :match="match"
                        :divisionId="d.id"
                      ></match-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import flatten from '@/helpers/ArrayFlatten'
import MatchCard from '@/components/Tournament/Match/NoDetailsMatchCard'

export default {
  data () {
    return {
      unlockedOnly: false,
      readyOnly: false,
      nolive: false,
      mini: false,
      remaining: []
    }
  },
  computed: {
    ...mapGetters(['tournament']),
    divMap () {
      return this.tournament.publicDivisions.map(div => {
        const all = this.remaining.includes(div.id)
        return {
          name: div.name,
          id: div.id,
          hydrated: div.hydrated,
          get matches () {
            return all ? this.remaining : this.live
          },
          live: flatten(div.publicRounds.map(r => {
            return r.allMatches.filter(f => f.status === 'Started')
          })),
          remaining: flatten(div.publicRounds.map(r => {
            return r.allMatches.filter(f => !f.complete)
          }))
        }
      }).filter(f => f.remaining.length > 0)
    }
  },
  methods: {
    toggle (id) {
      const i = this.remaining.findIndex(f => f === id)
      if (i === -1) {
        this.remaining.push(id)
      } else {
        this.remaining.splice(i, 1)
      }
    }
  },
  components: {
    MatchCard
  }
}
</script>

<style scoped>

</style>
